<template>
  <div class="row row-equal">
    <div
      class="flex xs12 md3"
      v-for="number of numbers"
      v-bind:key="number.id"
    >
      <va-card
        class="stripe"
        :style="{ 'border-color': getColor(number.brand) }"
      >
        <p class="text-center no-wrap">{{$t(number.total.text)}}</p>
        <p
          class="text-center display-2 mb-1"
          :style="{ color: getColor(number.brand) }"
        >
          <text-loading
            :loading="number.loading"
            :color="getColor(number.brand)"
          >
            <vue-numeric
              separator="."
              :empty-value="0"
              v-model="number.total.count"
              read-only
            />
          </text-loading>
        </p>
      </va-card>
    </div>
  </div>
</template>

<script>
const VueNumeric = () => import(/* webpackPrefetch: true */ 'vue-numeric')

export default {
  name: 'dashboard-info-block',
  components: { VueNumeric },
  data () {
    return {
      numbers: [],
    }
  },
  created () {
    this.fillNumbers()
    this.getNumbersData()
  },
  methods: {
    getColor (color) {
      return this.$themes[color]
    },
    fillNumbers () {
      const n = [
        {
          id: 'assistance',
          title: this.$t('dashboard.info.assistance'),
          brand: 'primary',
          loading: true,
          total: {
            text: 'dashboard.info.assistance',
            icon: 'entypo-globe',
            count: 0,
          },
        },
        {
          id: 'decisions',
          title: this.$t('dashboard.info.decisions'),
          brand: 'success',
          loading: true,
          total: {
            text: 'dashboard.info.decisions',
            icon: 'entypo-map',
            count: 0,
          },
        },
        {
          id: 'discipleship',
          title: this.$t('dashboard.info.discipleship'),
          brand: 'danger',
          loading: true,
          total: {
            text: 'dashboard.info.discipleship',
            icon: 'entypo-address',
            count: 0,
          },
        },
        {
          id: 'noequipement',
          title: this.$t('teams.equipements.none'),
          brand: 'primary',
          loading: true,
          total: {
            text: 'teams.equipements.none',
            icon: 'entypo-address',
            count: 0,
          },
        },
      ]

      this.numbers = n.slice(0)
    },
    async getNumbersData () {
      this.loadingError = false
      let d = null

      try {
        d = await this.$http.get('presentations/numbers')
      } catch (e) {
        this.loadingError = true
        return
      }

      const keys = Object.keys(d.data.data)
      for (const key of keys) {
        const obj = this.numbers.find(n => n.id === key)
        if (obj) {
          obj.loading = false
          obj.total.count = d.data.data[key]
        }
      }
    },
  },
}
</script>

<style lang="scss">
.row-separated {
  .flex + .flex {
    border-left: 1px solid #e3eaeb;
  }

  @include media-breakpoint-down(xs) {
    p:not(.display-2) {
      font-size: 0.875rem;
    }
  }
}

.dashboard {
  .va-card__header--over {
    @include media-breakpoint-up(md) {
      padding-top: 0 !important;
    }
  }

  .va-card {
    &.stripe {
      border-right: solid 5px;

      &__info {
        border-color: var(--brand-primary);
      }

      &__success {
        border-color: var(--brand-success);
      }

      &__danger {
        border-color: var(--brand-danger);
      }
    }
  }

  .va-card__image {
    @include media-breakpoint-up(md) {
      padding-bottom: 0 !important;
    }
  }
}
</style>
